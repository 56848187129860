<template>
<main id="main" class="auction">
  <ItemSearch
    :nameTag="nameTag"
    :parent="$data"
    :search="searchAuctionItems"
    :printItemsCsv="printItemsCsv"
    :favorite="favorite"
  />
</main>
</template>

<script>
import Methods from '@/api/methods'
import {defineComponent} from '@vue/composition-api'
import ItemSearch from '../../components/ItemList/ItemSearch'
export default defineComponent({
  components : {
    ItemSearch
  },
  data() {
    return {
      nameTag        : 'AUCTION_PAGE_SEARCH_HEADER',
      typeTag        : 'AUCTION_PAGE_SEARCH_TYPE',
      detailPageName : this.$define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME
    }
  },
  methods : {
    searchAuctionItems(params) {
      return Methods.apiExcute('public/search-auction-items', this.$i18n.locale, Object.assign({exhClass : 1}, params))
    },
    printItemsCsv(params) {
      return Methods.apiExcute('private/print-auction-items-csv', this.$i18n.locale, Object.assign({exhClass : 1}, params))
    },
    favorite(params) {
      return Methods.apiExcute('private/favorite-item', this.$i18n.locale, params)
    }
  }
})
</script>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
